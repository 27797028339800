// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-details-js": () => import("./../../../src/pages/blog-details.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index-2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-modular-frp-cooling-towers-js": () => import("./../../../src/pages/products/modular-frp-cooling-towers.js" /* webpackChunkName: "component---src-pages-products-modular-frp-cooling-towers-js" */),
  "component---src-pages-products-natural-draught-cooling-towers-js": () => import("./../../../src/pages/products/natural-draught-cooling-towers.js" /* webpackChunkName: "component---src-pages-products-natural-draught-cooling-towers-js" */),
  "component---src-pages-products-pultruded-frp-cooling-towers-js": () => import("./../../../src/pages/products/pultruded-frp-cooling-towers.js" /* webpackChunkName: "component---src-pages-products-pultruded-frp-cooling-towers-js" */),
  "component---src-pages-service-details-js": () => import("./../../../src/pages/service-details.js" /* webpackChunkName: "component---src-pages-service-details-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-turnkey-js": () => import("./../../../src/pages/turnkey.js" /* webpackChunkName: "component---src-pages-turnkey-js" */)
}

